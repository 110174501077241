<script setup></script>

<template>
  <div class="subscription-images">
    <img
      src="~/assets/images/subscription-paper.svg"
      alt="subscription paper icon"
      width="96"
      height="97"
    />
    <picture>
      <source
        media="(max-width: 768px)"
        srcset="~/assets/images/subscription-arrow_mobile.svg"
        width="26"
        height="24"
      />
      <img
        src="~/assets/images/subscription-arrow.svg"
        alt="subscription arrow icon"
        width="162"
        height="23"
      />
    </picture>
    <img
      src="~/assets/images/subscription-envelope.svg"
      alt="subscription envelope icon"
      width="126"
      height="126"
    />
    <picture>
      <source
        media="(max-width: 768px)"
        srcset="~/assets/images/subscription-arrow_mobile.svg"
        width="26"
        height="24"
      />

      <img
        src="~/assets/images/subscription-arrow.svg"
        alt="subscription arrow icon"
        width="162"
        height="23"
      />
    </picture>
    <img
      src="~/assets/images/subscription-mailbox.svg"
      alt="subscription mailbox icon"
      width="96"
      height="97"
    />
  </div>
</template>

<style lang="postcss" scoped>
.subscription-images {
  display: flex;
  align-items: center;
  gap: 20px;
  user-select: none;

  @media (--small-vp) {
    justify-content: center;
    gap: 10px;
    margin: 0 -12px;
    overflow: hidden;

    & > img {
      width: 80px;
    }
  }
}
</style>
