import instagram from '~/assets/images/socials/instagram.svg';
import telegram from '~/assets/images/socials/telegram.svg';
import youtube from '~/assets/images/socials/youtube.svg';
import facebook from '~/assets/images/socials/facebook.svg';
import twitter from '~/assets/images/socials/twitter.svg';
import vk from '~/assets/images/socials/vk.svg';

export const socialLinks = [
  {
    name: 'instagram',
    link: 'https://www.instagram.com/doxa_journal/',
    icon: instagram,
  },
  {
    name: 'telegram',
    link: 'https://t.me/doxajournal',
    icon: telegram,
    isBigIcon: true,
  },
  {
    name: 'youtube',
    link: 'https://www.youtube.com/channel/UCI8XeBXcahsbsR2XA9gIWoQ',
    icon: youtube,
  },
  {
    name: 'facebook',
    link: 'https://www.facebook.com/journaldoxa',
    icon: facebook,
  },
  {
    name: 'twitter',
    link: 'https://twitter.com/doxa_journal',
    accountName: '@doxa_journal',
    icon: twitter,
    isBigIcon: true,
  },
  { name: 'vk', link: 'https://vk.com/doxateam', icon: vk },
];
