<script setup>
import { useEventTracking } from '#imports';
import { socialLinks as links } from '~/constants/social-links';
</script>

<template>
  <ul class="social-links">
    <li
      v-for="item in links"
      :key="item.link"
      :class="{ 'social-links__item--big': item.isBigIcon }"
      class="social-links__item"
    >
      <span class="social-links__back"></span>
      <a
        :href="item.link"
        class="social-links__link"
        target="_blank"
        @click="
          useEventTracking(
            'click',
            `Click on social link ${item.name} in footer`
          )
        "
        ><img
          :class="{
            'social-links__icon--facebook': item.name === 'facebook',
          }"
          :src="item.icon"
          alt="social icon"
          class="social-links__icon"
        /><span class="social-links__icon-capture">{{ item.name }}</span></a
      >
    </li>
  </ul>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="postcss" scoped>
.social-links {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-items: center;
  gap: 20px;
  align-items: center;
  justify-content: center;
  max-width: var(--content-max-width);
  padding: 0 60px 20px;
  margin: 0 auto;

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: transparent;
    overflow: hidden;
    transition: var(--default-transition) background;
    z-index: 2;

    &:hover {
      background: var(--accent-color);

      @media (--tablet-vp) {
        background: transparent;
      }
    }

    &:focus {
      background: transparent;
    }

    &--big {
      width: 190px;
      height: 190px;
    }

    &:nth-child(1) {
      margin-top: 60px;
    }

    &:nth-child(2) {
      margin-top: 10px;
    }

    &:nth-child(4) {
      margin-top: auto;
    }

    &:nth-child(6) {
      margin-top: auto;
    }
  }

  &__back {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      180deg,
      #d886fb 0%,
      rgba(255, 133, 234, 20%) 100%
    );
    transition: var(--default-transition) opacity;
    opacity: 0.7;

    &:hover {
      opacity: 0;
    }
  }

  &__link {
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    user-select: none;

    &--facebook {
      transform: translate(3px, 13px);
    }
  }

  &__icon-capture {
    opacity: 0;
    visibility: hidden;
  }

  @media (--small-vp) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    padding: 0 12px 12px;
    gap: 0 12px;

    &__item {
      width: 75px;
      height: 75px;

      &--big {
        width: 115px;
        height: 115px;
      }

      &:nth-child(1) {
        order: 2;
        margin-top: 0;
      }

      &:nth-child(2) {
        order: 1;
        margin-top: 10px;
      }

      &:nth-child(3) {
        order: 3;
        margin-top: 20px;
      }

      &:nth-child(4) {
        order: 4;
        margin-top: auto;
      }

      &:nth-child(5) {
        order: 5;
        margin-top: auto;
      }

      &:nth-child(6) {
        order: 6;
        margin-top: auto;
      }
    }

    &__icon {
      transform: scale(0.6);

      &--facebook {
        transform: scale(0.6) translate(5px, 16px);
      }
    }
  }
}
</style>
