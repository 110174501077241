<script setup>
import { ref, useAsyncData, useRuntimeConfig } from '#imports';
import { toast } from 'vue3-toastify';
import { nanoid } from 'nanoid';
import { config } from '~/services/toasts/config';
import { offerData } from '~/components/payment-form/constants';
import { useEventTracking } from '~/utils/use-event-tracking';

defineProps({
  isNewLayout: {
    type: Boolean,
    default: false,
  },
  height: {
    type: Number,
    default: undefined,
  },
});

const email = ref();
const success = ref(false);

const runtimeConfig = useRuntimeConfig();
const { groups: groupId } = runtimeConfig.public.mailerlite;

const data = {
  success: {
    title: 'Спасибо, что подписались!',
    text: [
      'Мы отправили вам приветственное&nbsp;письмо.',
      'Если оно не пришло, проверьте&nbsp;папку «Спам».',
    ],
  },
};

async function submit() {
  const requestId = nanoid();
  const { data: result, error } = await useAsyncData(requestId, () =>
    $fetch('/api/v1/create-email-subscription', {
      method: 'POST',
      body: {
        email: email.value.value,
        groups: [groupId],
      },
    })
  );

  // @TODO: handle error statuses and show human-readable error messages
  if (error.value) {
    const { message } = error.value;
    toast.error(message, config);
    return;
  }

  if (result.value.status === 'active') {
    useEventTracking('Subscribe', 'User successfully subscribed');
    success.value = true;
  }
}
</script>

<template>
  <div
    :class="{ 'subscription-form--new-layout': isNewLayout }"
    class="subscription-form"
  >
    <form v-if="!success" @submit.prevent="submit">
      <div class="subscription-form__form">
        <input
          ref="email"
          autocomplete="email"
          class="subscription-form__input"
          name="email"
          placeholder="Email"
          required
          type="email"
        />
        <ButtonComponent
          button-caption="Подписаться"
          theme="yellow"
          type="submit"
          @click="useEventTracking('Subscribe', 'Attempt to subscribe')"
        />
      </div>
      <div v-if="!success" class="subscription-form__agreement">
        Подписываясь на рассылку, вы соглашаетесь с
        <BaseExternalLink :href="offerData.email"> условиями </BaseExternalLink>
      </div>
    </form>

    <Transition name="fade">
      <div v-if="success" class="subscription-form__success">
        <h2 v-once v-html="data.success.title" />
        <p
          v-for="item in data.success.text"
          v-once
          :key="item"
          v-html="item"
        ></p>
      </div>
    </Transition>
  </div>
</template>

<style lang="postcss" scoped>
.subscription-form {
  width: 100%;
  max-width: 842px;

  --subscribe-input-height: 70px;
  --subscribe-input-height--mobile: 56px;

  &__form {
    display: flex;
    gap: 10px;

    @media (--small-vp) {
      flex-direction: column;
    }
  }

  &__input {
    font-family: var(--main-font-family);
    font-size: var(--body-font-size);
    font-weight: 500;
    box-sizing: border-box;
    width: 100%;
    max-width: 500px;
    height: var(--subscribe-input-height);
    padding: 20px 20px;
    color: var(--accent-color);
    border: none;
    border-radius: var(--border-radius);
    background-color: var(--control-background-color);

    &::placeholder {
      color: rgba(12, 26, 17, 40%);
    }

    @media (--small-vp) {
      max-width: 100%;
      height: var(--subscribe-input-height--mobile);
      padding: 12px 18px;
    }
  }

  &__agreement {
    font-size: 14px;
    margin: 6px 10px;
    color: #999;

    @media (--small-vp) {
      max-width: 230px;
      margin: 6px auto;
      text-align: center;
    }
  }

  &__success {
    font-size: 24px;
    line-height: 38px;
    margin: 32px 0;

    & h2 {
      font-size: 48px;
      line-height: 56px;
      margin-bottom: 16px;
    }

    @media (--small-vp) {
      font-size: 18px;
      line-height: 24px;

      & h2 {
        font-size: 28px;
        line-height: 36px;
      }
    }
  }

  &--new-layout {
    max-width: 100%;
    margin-top: 24px;
    margin-bottom: 64px;

    --subscribe-input-height--new-layout: 64px;

    & .subscription-form__form {
      width: 100%;
    }

    & .subscription-form__input {
      font-size: 24px;
      width: 50%;
      height: var(--subscribe-input-height--new-layout);
      padding: 14px 16px;
      background-color: var(--white-color);

      @media (--small-vp) {
        width: 100%;
      }
    }

    & .subscription-form__success {
      padding: 16px 24px;
      transition: var(--default-transition) background-color;
      border: 1px solid rgba(17, 17, 17, 10%);
      border-radius: 16px;
      background-color: rgba(var(--white-color--rgb), 0.7);

      @media (--desktop-vp) {
        text-align: center;
      }

      & h2 {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 0;

        @media (--small-vp) {
          font-size: 22px;
          line-height: 28px;
          margin-bottom: 8px;
        }
      }

      & p {
        font-size: 18px;
        line-height: 28px;

        @media (--small-vp) {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }

    & .subscription-form__agreement {
      color: #666;
    }

    & .button {
      font-size: 24px;
      width: 30%;
      max-width: none;
      height: var(--subscribe-input-height--new-layout);
      background-color: var(--green-color);

      &:hover {
        background-color: var(--green-color-hover);
      }

      &:active {
        background-color: var(--green-color-active);
      }

      @media (--small-vp) {
        width: 100%;
      }
    }
  }
}
</style>
