<script setup>
import { burgerMenuList } from '~/constants/burger-menu';
import { computed, useEventTracking, useRoute } from '#imports';

const emit = defineEmits(['close']);
const currentRoute = useRoute();
const isSameRoute = (route) => currentRoute.path === route;

function getRoute(targetRoute) {
  return isSameRoute(targetRoute) ? undefined : targetRoute;
}

const currentMenuItems = computed(() =>
  burgerMenuList.filter(({ route }) => !isSameRoute(route))
);

function handleClick(name) {
  useEventTracking('click', `Click on ${name} menu item`);
  emit('close');
}
</script>

<template>
  <nav class="menu">
    <ul class="menu__list">
      <li v-for="item in currentMenuItems" :key="item.name" class="menu__item">
        <a
          :href="getRoute(item.route)"
          class="menu__item-link"
          @click="handleClick(item.name)"
        >
          {{ item.name }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<style lang="postcss" scoped>
.menu {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  &__list {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  &__item {
    font-family: var(--title-font-family);
    font-size: var(--body-font-size);
    line-height: var(--body-line-height);
    margin-bottom: 8px;
    transition: var(--default-transition) background-color;
    color: #fffafd;
    border-radius: 20px;
    background-color: var(--accent-color);

    &:first-child {
      display: none;
    }

    @media (--desktop-vp) {
      &:hover {
        background-color: #635bff;
      }
    }

    @media (--small-vp) {
      &:first-child {
        display: block;
      }

      &:hover {
        background-color: var(--accent-color);
      }
    }
  }

  &__item-link {
    display: inline-flex;
    padding: 8px 16px;
  }

  &::before {
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    width: 490px;
    height: 490px;
    content: '';
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #fff 0,
      rgba(255, 255, 255, 80%) 60%,
      rgba(255, 255, 255, 0%) 100%
    );
  }

  @media (--small-vp) {
    &__item {
      margin-bottom: 6px;
      border-radius: 16px;
    }

    &__item-link {
      padding: 8px 16px;
    }
  }
}
</style>
