<script setup>
defineProps({
  isHideDonationForm: { type: Boolean, default: false },
  isSpecial: { type: Boolean, default: false },
});
const desc = `Мы рассказываем про военное вторжение России в Украину, протесты и репрессии. Мы считаем, что сейчас, когда десятки медиа закрылись или перестали освещать войну, доступ к независимой информации важен как никогда.`;
const title = `В эти мрачные времена мы&nbsp;продолжаем работать для вас`;
const specialTitle = `Сообщить новую информацию можно, связавшись с нами по электронной почте или написав в Telegram-бот`;
</script>

<template>
  <div class="donation-block">
    <div
      class="donation-block__container"
      :class="{
        'donation-block__container--padding': isHideDonationForm,
      }"
    >
      <div class="donation-block__top">
        <div class="donation-block__text">
          <div
            class="donation-block__top-title"
            v-html="isSpecial ? specialTitle : title"
          />
          <div
            class="donation-block__top-description"
            v-html="isSpecial ? '' : desc"
          ></div>
        </div>
        <div class="donation-block__contacts">
          <div v-if="!isSpecial" class="donation-block__contacts-title">
            Связаться с&nbsp;нами
          </div>
          <div class="donation-block__contacts-wrapper">
            <div class="donation-block__contacts-item-wrapper">
              <div v-if="!isSpecial" class="donation-block__contacts-subtitle">
                По почте
              </div>
              <div class="donation-block__contacts-item">
                <a
                  href="mailto:hello@doxa.team"
                  class="donation-block__contacts-link"
                >
                  <span>hello</span><br />
                  <span>@doxa.team</span>
                </a>
              </div>
            </div>
            <div class="donation-block__contacts-item-wrapper">
              <div v-if="!isSpecial" class="donation-block__contacts-subtitle">
                Через Telegram
              </div>
              <div
                class="donation-block__contacts-item donation-block__contacts-item--telegram"
              >
                <a
                  href="https://t.me/doxateambot"
                  class="donation-block__contacts-link"
                  target="_blank"
                >
                  <span>@doxateam</span><br />
                  <span>BOT</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DonationBlockPaymentWrapper v-if="!isHideDonationForm" />
  </div>
</template>

<style lang="postcss" scoped>
.donation-block {
  position: relative;
  background-color: #fff6fa;

  &__container {
    position: relative;
    z-index: 2;
    max-width: var(--content-max-width);
    margin: 0 auto;
    padding: 0 60px;
    overflow: hidden;

    &--padding {
      padding-bottom: 136px;
    }
  }

  &__contacts-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 18px;
  }

  &__top {
    display: flex;
    gap: 20px;
  }

  &__text {
    max-width: 728px;
  }

  &__top-title {
    margin-bottom: 16px;
    font-family: var(--title-font-family);
    font-size: var(--h2-font-size);
    line-height: var(--h2-line-height);
    font-weight: 700;
  }

  &__top-description {
    font-size: var(--body-font-size);
    line-height: var(--body-line-height);
    font-weight: 500;
    margin: 24px 0;
  }

  &__contacts-title {
    font-family: var(--title-font-family);
    font-size: var(--h3-font-size);
    line-height: var(--h3-line-height);
    font-weight: 700;
    margin-bottom: 28px;
  }

  &__contacts-subtitle {
    font-size: 14px;
    line-height: 22px;
    color: rgba(var(--black-color--rgb), 40%);
  }

  &__contacts-item {
    width: 233px;
    padding: 6px;
    margin-bottom: 26px;
    border-radius: 8px;
    font-family: var(--title-font-family);
    color: var(--accent-color);
    background: rgba(var(--accent-color--rgb), 10%);
    transition: var(--default-transition);

    .donation-block__contacts-link {
      font-weight: 700;

      span:first-child {
        font-size: 72px;
        line-height: 72px;
      }

      span:last-child {
        font-size: 28px;
        line-height: 20px;
      }
    }

    &:hover {
      opacity: 0.9;
    }
  }

  &__contacts-item--telegram {
    color: #4aa3e1;
    background: rgba(74, 163, 225, 10%);

    .donation-block__contacts-link {
      span:first-child {
        font-size: 28px;
        line-height: 20px;
      }

      span:last-child {
        font-size: 79px;
        line-height: 70px;
      }
    }
  }

  @media (--tablet-vp) {
    &__top {
      flex-direction: column;
      gap: 0;
    }
  }

  @media (--small-vp) {
    &__container {
      padding: 0 12px 50px;

      &--padding {
        padding-bottom: 60px;
      }
    }

    &__top-title {
      margin-bottom: 8px;
      font-size: 24px;
      line-height: 30px;
    }

    &__top-description {
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 22px;
    }

    &__contacts-title {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 16px;
    }

    &__contacts-subtitle {
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 8px;
    }

    &__contacts-item {
      width: 100%;
      padding: 3px;
      margin-bottom: 0;
      border-radius: 6px;

      .donation-block__contacts-link {
        display: block;
        text-align: center;

        span:first-child {
          font-size: 54px;
          line-height: 50px;
        }

        span:last-child {
          font-size: 20px;
          line-height: 16px;
        }
      }
    }

    &__contacts-item--telegram {
      .donation-block__contacts-link {
        span:first-child {
          font-size: 22px;
          line-height: 16px;
        }

        span:last-child {
          font-size: 54px;
          line-height: 50px;
        }
      }
    }
  }
}
</style>
