<script setup>
import { useEventTracking } from '#imports';

defineProps({
  isHideSubscriptionBlock: { type: Boolean, default: false },
});

const handleClick = () => {
  useEventTracking('click', 'Go to /antiwarletter');
};
</script>

<template>
  <div class="subscription">
    <SubscriptionImages />
    <div class="subscription__title-wrapper">
      <NuxtLink to="/antiwarletter" @click="handleClick">
        <img
          alt="Антивоенная рассылка"
          class="subscription__title"
          height="210"
          src="~/assets/images/subscription-title.svg"
          width="996"
        />
      </NuxtLink>
    </div>
    <SubscriptionForm />
  </div>
</template>

<style lang="postcss" scoped>
.subscription {
  &__title-wrapper {
    position: relative;
    width: 100%;
    max-width: 996px;
    margin-bottom: 20px;
    user-select: none;
  }

  &__title {
    box-sizing: content-box;
    width: 100%;
    max-width: 996px;
    height: auto;
  }

  &__subtitle {
    font-family: var(--main-font-family);
    font-size: 18px;
    line-height: 22px;
    position: absolute;
    top: 116px;
    right: -25px;
    max-width: 256px;
    opacity: 0.6;
    color: #706c6e;
  }

  @media (--small-vp) {
    &__title-wrapper {
      margin-bottom: 16px;
    }

    &__subtitle {
      font-size: 14px;
      line-height: 18px;
      position: static;
      max-width: 306px;
      opacity: 1;
      color: var(--black-color);
    }
  }
}
</style>
