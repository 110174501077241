<script setup>
import { computed, useRoute } from '#imports';
import { useEventTracking } from '@/utils/use-event-tracking';

defineProps({
  isSpecial: { type: Boolean, default: false },
  isHideDonationBlock: { type: Boolean, default: false },
  isHideDonationForm: { type: Boolean, default: false },
  isHideSubscriptionBlock: { type: Boolean, default: false },
  isShowOnlySocials: { type: Boolean, default: false },
});

const route = useRoute();

const isMainPage = computed(() => route.name === 'index');
</script>

<template>
  <footer id="footer" class="footer">
    <div
      v-if="!isHideDonationBlock && !isShowOnlySocials"
      class="footer__donation"
    >
      <DonationBlock
        :is-hide-donation-form="isHideDonationForm"
        :is-special="isSpecial"
        @click.once="
          useEventTracking(
            'Payment Form',
            'User interacted with donation form in footer'
          )
        "
      />
    </div>
    <div
      v-if="!isHideSubscriptionBlock && !isShowOnlySocials"
      class="footer__subscription"
    >
      <SubscriptionBlock />
    </div>
    <div class="footer__socials">
      <SocialLinks />
    </div>
    <div
      v-if="isMainPage && !isShowOnlySocials"
      class="footer__sanity-logo-wrap"
    >
      <a
        class="footer__sanity-logo"
        href="https://www.sanity.io/"
        target="_blank"
      >
        <img alt="sanity-logo" src="~/assets/images/sanity-logo.svg" />
      </a>
    </div>
  </footer>
</template>

<style lang="postcss" scoped>
.footer {
  background-color: var(--page-background-color);

  &__subscription {
    z-index: 1;
    position: relative;
    max-width: var(--content-max-width);
    margin: 0 auto;
    padding: 0 90px 90px;
  }

  &__sanity-logo-wrap {
    display: flex;
    padding: 80px 40px 32px;
  }

  &__sanity-logo {
    margin-left: auto;

    img {
      width: 118px;
      height: 24px;
    }
  }

  @media (--small-vp) {
    &__subscription {
      padding: 0 12px 36px;
    }

    &__sanity-logo-wrap {
      padding: 32px 24px 16px;
    }

    &__sanity-logo {
      img {
        width: 78px;
        height: 16px;
      }
    }
  }
}
</style>
