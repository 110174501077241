<script setup>
import { onMounted, onUnmounted, ref, useRoute, useTrackEvent } from '#imports';

const props = defineProps({
  isShady: { type: Boolean, default: false },
  isTransparent: { type: Boolean, default: false },
  forceTransparent: { type: Boolean, default: false },
  isSimpleHeader: { type: Boolean, default: false },
  isColoredLogo: { type: Boolean, default: false },
});
const currentRoute = useRoute();
const isLinkToEngPage = currentRoute.path === '/act';
const actLinkLabel = isLinkToEngPage ? 'Act now' : 'Действовать';

function getRoute() {
  return isLinkToEngPage ? '/en/act' : '/act';
}
const headerElement = ref();
const isElementSticky = ref(false);
const isElementTransparent = ref(props.isTransparent);
const toggleIsSticky = (entries) => {
  isElementSticky.value =
    entries[0].intersectionRatio !== 1 &&
    entries[0].boundingClientRect.top <= 1;
};

const toggleIsTransparent = () => {
  isElementTransparent.value = window.pageYOffset < 110 && props.isTransparent;
};

onMounted(() => {
  const observer = new IntersectionObserver(toggleIsSticky, {
    rootMargin: '-1px 0px 0px 0px',
    threshold: [1],
  });

  observer.observe(headerElement.value);

  if (!props.forceTransparent) toggleIsTransparent();

  if (props.isTransparent && !props.forceTransparent) {
    window.addEventListener('scroll', toggleIsTransparent);
  }
});

onUnmounted(() => {
  window.removeEventListener('scroll', toggleIsTransparent);
});
function trackEvent(eventType, name, placement) {
  useTrackEvent(eventType, {
    props: {
      name,
      placement,
    },
  });
}

const handleClick = (name, place) => {
  trackEvent('click', name, place);
};
</script>

<template>
  <header
    ref="headerElement"
    :class="{
      'header--sticky': isElementSticky,
      'header--shady': isShady,
      'header--transparent': isElementTransparent,
      'header--force-transparent': forceTransparent,
      'header--simple': isSimpleHeader,
    }"
    class="header"
  >
    <div class="header__container">
      <NuxtLink
        :to="{ name: 'index' }"
        class="header__link"
        exact-active-class="header__link--active"
        @click="handleClick('Click on logo', 'header')"
      >
        <TheLogo :is-colored-logo="isColoredLogo" />
      </NuxtLink>
      <nav v-if="!isSimpleHeader" class="header__nav">
        <NuxtLink
          :to="{ name: 'about' }"
          class="header__link header__nav-item"
          exact-active-class="header__link--active"
          @click="handleClick('Click on about link', 'header')"
          >О нас
        </NuxtLink>
        <NuxtLink
          :to="{ name: 'contact-us' }"
          class="header__link header__nav-item header__nav-item--contacts"
          exact-active-class="header__link--active"
          @click="handleClick('Click on contact us link', 'header')"
          >Связаться
        </NuxtLink>
        <NuxtLink
          :to="getRoute()"
          class="header__link header__nav-item header__nav-item--donation"
          exact-active-class="header__link--active"
          @click="handleClick('Click on act link', 'header')"
          >{{ actLinkLabel }}
        </NuxtLink>
        <BaseTooltip
          placement="left-start"
          theme="main-burger-menu"
          @click="useTrackEvent('click', 'Click on burger menu')"
        >
          <template #reference>
            <button class="header__burger-button">
              <span></span>
              <span></span>
              <span></span>
            </button>
          </template>
          <template #content="{ close }">
            <TheBurgerMenu @close="close" />
          </template>
        </BaseTooltip>
      </nav>
    </div>
  </header>
</template>

<style lang="postcss" scoped>
.header {
  transition: var(--default-transition) background;
  border-radius: 0 0 24px 24px;
  background-color: var(--accent-color);

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: var(--content-max-width);
    height: var(--main-header-height);
    margin: 0 auto;
    padding: 0 60px;
  }

  &__link--active {
    cursor: default;
    user-select: none;
    pointer-events: none;
  }

  &__nav {
    display: flex;
    align-items: center;
    gap: 50px;
  }

  &__nav-item {
    font-family: var(--title-font-family);
    font-size: 13px;
    font-weight: var(--title-font-weight);
    line-height: 19px;
    transition: var(--default-transition) opacity;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    opacity: 1;
    color: rgba(var(--white-color--rgb), 100%);

    &--donation {
      color: var(--accent-second-color);
    }

    &:hover {
      opacity: 0.6;
    }

    @media (--small-vp) {
      &--contacts {
        display: none;
      }
    }
  }

  &__logo-image {
    width: 114px;
    height: 28px;
    transition: var(--default-transition) opacity;
    opacity: 0.6;
  }

  &__burger-button {
    position: relative;
    display: block;
    width: 26px;
    height: 22px;
    margin: auto;
    cursor: pointer;

    span {
      position: absolute;
      display: block;
      width: 26px;
      height: 4px;
      transition: opacity 0.3s ease-in-out;
      opacity: 0.7;
      background-color: var(--white-color);

      &:first-child {
        top: 0;
      }

      &:nth-child(2) {
        top: 9px;
      }

      &:last-child {
        bottom: 0;
      }
    }

    &:hover span {
      opacity: 1;
    }
  }

  &__burger-button-image {
    width: 24px;
    height: 24px;
    transition: var(--default-transition) opacity;
    opacity: 0.6;
  }

  &--transparent {
    background: transparent;

    .header__burger-button-image {
      opacity: 1;
    }
  }

  &--simple {
    position: absolute;
    z-index: 10;
    top: 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    background: transparent;
  }

  &--sticky {
    .header__nav-item {
      color: var(--white-color);

      &--donation {
        color: var(--accent-second-color);
      }
    }

    .header__burger-button-image {
      opacity: 1;
    }
  }

  &--force-transparent {
    background: transparent;

    .header__burger-button-image {
      opacity: 1;
    }

    &.header--sticky {
      background: var(--accent-color);
    }
  }

  &--shady {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0%) 0%,
      rgba(0, 0, 0, 80%) 100%
    );

    .header__nav-item {
      color: var(--white-color);

      &--donation {
        color: var(--accent-second-color);
      }
    }

    .header__burger-button-image {
      opacity: 1;
    }
  }

  &--shady.header--sticky {
    background: var(--accent-color);
  }

  &--transparent,
  &--sticky,
  &--force-transparent,
  &--shady {
    .header__burger-button-image {
      &:hover {
        opacity: 0.6;
      }
    }
  }

  @media (--small-vp) {
    border-radius: 0 0 16px 16px;

    &--sticky.header--pride::before {
      content: none;
    }

    &__container {
      padding: 0 12px;
    }

    &__nav {
      gap: 25px;
    }

    &__nav-item {
      font-size: 10px;
      line-height: 14px;
    }

    &__logo-image {
      width: 95px;
      height: 20px;
    }
  }
}
</style>
