export const burgerMenuList = [
  { name: 'Связаться', route: '/contact-us' },
  { name: 'Все материалы', route: '/articles' },
  { name: 'Новости', route: '/news' },
  {
    name: 'Что происходит',
    route: '/themes/what-is-happening',
  },
  { name: 'Истории', route: '/themes/stories' },
  { name: 'Инструкции', route: '/themes/guides' },
  { name: 'В свободное время', route: '/themes/in-spare-time' },
  { name: 'Рассылка', route: '/antiwarletter' },
];
